import React, { useState } from "react";
import SignInFormLayout from "./SignInFormLayout";
import { Box, Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { formContentDiv } from "../../../../lib/styleConstants";
import SignInFormHeader from "./SignInFormHeader";
import {
  clientSignInUpTextConstants,
  enterPriseUserRole,
  guestUserSignInRoute,
  userRoles,
} from "../../../../lib/constants";
import theme from "../../../../theme";
import AppButton from "../../../global/elements/AppButton";
import { linkClientData, updateClient } from "../../../../services/client";
import { useSnackbar } from "notistack";
import { navigate } from "gatsby";

const styles = {
  textColor: {
    color: theme.palette.grey.warmGrey,
  },
  gridContainer: { marginTop: "50px" },
  buttonCls: {
    textAlign: { xs: "center", md: "right" },
    marginTop: { xs: "10px", md: "70px" },
  },
};

const MemberJoinEnterprise = ({
  companyName,
  clientId,
  enterPriseId,
  client,
}) => {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const joinTeam = async () => {
    if (client?.enterPriseId) {
      if (client.enterPriseId == enterPriseId) {
        enqueueSnackbar("You have already joined this team.", {
          variant: "error",
        });
      } else if (client.enterPriseId != enterPriseId) {
        enqueueSnackbar("You are part of another team.", {
          variant: "error",
        });
      }
    } else {
      try {
        const updates = {
          enterPriseId,
          enterPrise: true,
          roleType: enterPriseUserRole.general,
        };
        setLoading(true);
        const resp = await updateClient(clientId, updates);
        if (resp.status == "Success") {
          linkClientData({ clientId, enterPriseId });
          enqueueSnackbar("You have successfully joined the team", {
            variant: "success",
          });
          navigate(guestUserSignInRoute);
        } else {
          enqueueSnackbar("Error in joing the team", { variant: "error" });
        }
      } catch (e) {
        console.log("Error", e);
      } finally {
        setLoading(false);
      }
    }
  };
  return (
    <>
      <SignInFormLayout isClient={true}>
        <Box sx={formContentDiv}>
          <SignInFormHeader
            title={"Join my team"}
            subtitle={clientSignInUpTextConstants.signUpSubTitle}
            user={userRoles.client}
          />

          <Grid container sx={styles.gridContainer}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" sx={styles.textColor}>
                You’ve been invited by :{" "}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1Med" sx={styles.textColor}>
                {companyName}
              </Typography>
            </Grid>

            <Grid item xs={12} md={8} sx={styles.buttonCls}>
              <AppButton
                look="inverted"
                small
                onClick={() => window.history.back()}
                label="No, thanks"
                noIcon
              />
            </Grid>
            <Grid item xs={12} md={4} sx={styles.buttonCls}>
              <AppButton
                // type="submit"
                look="black"
                label="Join Team"
                submittingState={loading}
                onClick={() => joinTeam()}
              />
            </Grid>
          </Grid>
        </Box>
      </SignInFormLayout>
    </>
  );
};

MemberJoinEnterprise.propTypes = {
  companyName: PropTypes.string,
  clientId: PropTypes.string.isRequired,
  enterPriseId: PropTypes.string.isRequired,
  client: PropTypes.object.isRequired,
};

MemberJoinEnterprise.defaultProps = {
  companyName: "",
};

export default MemberJoinEnterprise;
