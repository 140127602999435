import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import firebase from "src/firebase";
import MemberSignup from "../../../components/forms/SignInUp/MemberSignup";
import { decryptString } from "../../../lib/helpers";
import { flexJustifyConentCenter } from "../../../lib/styleConstants";
import { getClientfromEmail } from "../../../services/clients";
import UniversalLoader from "../../../components/global/elements/UniversalLoader";
import MemberJoinEnterprise from "../../../components/forms/SignInUp/components/MemberJoinEnterprise";

const styles = {
  boxContainer: {
    minHeight: "80vh",
    alignItems: "center",
  },
};

const InvalidLink = () => {
  return (
    <Box sx={{ ...flexJustifyConentCenter, ...styles.boxContainer }}>
      <Typography variant="h5">This is invalid link</Typography>
    </Box>
  );
};

const TeamMemberSignUp = ({ id }) => {
  const decryptObj = decryptString(id);
  const [loading, setLoading] = useState(true);
  const [userFound, setUserFound] = useState(null);

  useEffect(() => {
    fetchUser(decryptObj?.email);
  }, [decryptObj?.email]);

  const fetchUser = async (email) => {
    try {
      const client = await getClientfromEmail({
        emailId: email,
        firebase,
      });
      if (client) {
        setUserFound(client);
      }
    } catch (e) {
      console.log("Error", e);
    } finally {
      setLoading(false);
    }
  };

  const CheckUser = () => {
    return (
      <>
        {loading ? (
          <UniversalLoader />
        ) : userFound ? (
          <MemberJoinEnterprise
            {...decryptObj}
            clientId={userFound?.id}
            client={userFound}
          />
        ) : (
          <MemberSignup {...decryptObj} />
        )}
      </>
    );
  };
  return <>{decryptObj ? <CheckUser /> : <InvalidLink />}</>;
};

TeamMemberSignUp.propTypes = {
  id: PropTypes.string,
};

TeamMemberSignUp.defaultProps = {
  id: undefined,
};

export default TeamMemberSignUp;
