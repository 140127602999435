import React, { useState } from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import firebase from "src/firebase";
import SignInFormLayout from "./components/SignInFormLayout";
import { formContentDiv, formMargin } from "../../../lib/styleConstants";
import SignInFormHeader from "./components/SignInFormHeader";
import {
  clientSignInUpTextConstants,
  enterPriseUserRole,
  formIdConstant,
  unAuthorizedDomain,
  userRoles,
} from "../../../lib/constants";
import GoogleButton from "../../global/elements/GoogleButton";
import SignInOrStatement from "./components/SignInOrStatement";
import {
  createClientForGoogleLogin,
  handleClientRegistration,
  handlePilotRegistrationUsingGoogle,
} from "../../../services/user-registration";
import { getClientById } from "../../../services/client";
import { getPilot } from "../../../services/pilots";
import { loginUserLocally } from "../../../services/auth";
import FormComponent from "./FormComponent";
import { TeamMemberValidationSchema } from "./ValidationSchemas";

const MemberSignup = ({ name, enterPriseId, email, companyName, apiKey }) => {
  const formInitialValues = {
    email: email,
    password: "",
    googleSignup: false,
    name: name,
    companyName: companyName,
    jobTitle: "",
    enterPrise: true,
    enterPriseId,
    apiKey,
  };
  const formType = "signup";
  // const [emailExists, setEmailExists] = useState(false);
  const [errorMessageLabel, setErrorMessageLabel] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [hasError, showErrorMessage] = useState(false);
  const [initialValues, setInitialValues] = useState(formInitialValues);
  const [validationSchema, setValidationSchema] = useState(
    TeamMemberValidationSchema(formType)
  );

  const loginUser = (values, setSubmitting) => {
    try {
      showErrorMessage(false);
      if (values.googleSignup) {
        createGoogleSignupClient(values, setSubmitting);
      } else {
        signUpActionUsingEmail(values, setSubmitting);
      }
    } catch (e) {
      console.log("Error", e);
    }
  };

  const signUpActionUsingEmail = async (values, setSubmitting) => {
    const userData = values;
    userData.roleType = enterPriseUserRole.general;
    await handleClientRegistration({ firebase, userData })
      .then((snapshot) => {
        if (snapshot.status === "Success") {
          gotoDashboard(userData);
        } else if (snapshot.status === "Error") {
          showErrorMessage(true);
          setErrorMessageLabel("Error in signup");
          setErrorMessage(snapshot?.message);
        }
        return;
      })
      .catch((error) => {
        console.log("error", error);
        if (error?.code === "auth/email-already-in-use") {
          showErrorMessage(true);
          setErrorMessageLabel("Email already exists");
          setErrorMessage(
            "Please login instead of creating a new account or use a new email address. In case you have mistakenly signed up as a drone operator or as a client, contact support to reset your account."
          );
        } else if (error?.code === "auth/weak-password") {
          showErrorMessage(true);
          setErrorMessageLabel("Weak password");
          setErrorMessage("Password should be at least 6 characters.");
        } else {
          showErrorMessage(true);
          setErrorMessageLabel("Unexpected error");
          setErrorMessage(
            "We could not proceed your request. Please try again later or contact support for help. Share this error message with them so they can help you. The error is: --" +
              error?.code
          );
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const createGoogleSignupClient = async (values, setSubmitting) => {
    // if (!emailExists) {
    values["isNewUser"] = true;
    values["roleType"] = enterPriseUserRole.general;
    return createClientForGoogleLogin(firebase, values.id, values)
      .then(() => {
        gotoDashboard(values);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const gotoDashboard = (values) => {
    const userData = {
      user: {
        role: userRoles.client,
        email: values.email,
        userID: values.id,
      },
      userProfile: values,
    };
    loginUserLocally(userData);
  };

  const googleSignUpProvider = async () => {
    try {
      // setEmailExists(false);
      showErrorMessage(false);
      const loginUserData = await handlePilotRegistrationUsingGoogle({
        userType: userRoles.client,
      });
      let data = [];
      if (loginUserData.email !== email) {
        showErrorMessage(true);
        setErrorMessageLabel("You can't use another email");
        setErrorMessage(
          `This invitation link can only be used for ${email} email only`
        );
        return;
      }
      if (loginUserData) {
        data = await Promise.all([
          getClientById(firebase, loginUserData?.uid),
          getPilot(firebase, loginUserData?.uid),
        ]);
      }
      if (data && data[1]) {
        // this means this email is already associated with another account
        // setEmailExists(true);
        showErrorMessage(true);
        setErrorMessageLabel("Email already in use");
        setErrorMessage(
          "This email address is already in use by another account. Please use another email address to sign in. Did you sign up as a client or a drone operator? If unsure, contact support to help you."
        );
        firebase
          .auth()
          .signOut()
          .then(() => {
            localStorage.clear();
          });
        return;
      }
      if (data[0] && loginUserData) {
        if (data[0] && data[0].deleted) {
          showErrorMessage(true);
          setErrorMessageLabel("Unexpected error");
          setErrorMessage("This user is deleted");
          return;
        }
        const userData = {
          user: {
            role: userRoles.client,
            email: data[0].email,
            userID: loginUserData.uid,
            accessToken: loginUserData.accessToken,
            roleType: data[0].roleType,
          },
          userProfile: data[0],
        };
        loginUserLocally(userData);
      } else if (!data[0] && loginUserData) {
        let currentUser = loginUserData.auth.currentUser;
        const domain = currentUser.email.split("@")[1];
        if (unAuthorizedDomain.includes(domain)) {
          currentUser
            .delete()
            .then(() => {
              showErrorMessage(true);
              setErrorMessageLabel("Domain Restricted");
              setErrorMessage(
                "Please register with a company email. As a B2B platform, we do not accept personal emails."
              );
            })
            .catch((error) => {
              console.log("Error in deletion!", error);
            });
        } else {
          setInitialValues({
            ...initialValues,
            email: loginUserData?.email,
            name: loginUserData?.displayName || name,
            id: loginUserData?.uid,
            googleSignup: true,
            accessToken: loginUserData.accessToken,
            ...loginUserData,
          });
          setValidationSchema(TeamMemberValidationSchema(formType));
        }
      }
    } catch (e) {
      console.log("Error", e);
      showErrorMessage(true);
      setErrorMessageLabel("Error");
      setErrorMessage(
        "We could not proceed your request. Please try again later."
      );
    }
  };

  return (
    <SignInFormLayout isClient={true}>
      <Box sx={formContentDiv}>
        <SignInFormHeader
          title={clientSignInUpTextConstants.signUpTitle}
          subtitle={clientSignInUpTextConstants.signUpSubTitle}
          user={userRoles.client}
        />
        <Box sx={formMargin}>
          <GoogleButton signupProvider={googleSignUpProvider} />
        </Box>
        <SignInOrStatement />
        <FormComponent
          initialValues={initialValues}
          validationSchema={validationSchema}
          loginUser={loginUser}
          formType={formType}
          hasError={hasError}
          errorMessageLabel={errorMessageLabel}
          errorMessage={errorMessage}
          formId={formIdConstant.memberSignUp}
          disabledEmail
        />
      </Box>
    </SignInFormLayout>
  );
};

MemberSignup.propTypes = {
  name: PropTypes.string.isRequired,
  enterPriseId: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  companyName: PropTypes.string,
  apiKey: PropTypes.string.isRequired,
};

MemberSignup.defaultProps = {
  companyName: "",
};

export default MemberSignup;
